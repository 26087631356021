.header_dropdown_item {
    justify-content: space-between;
    width: 100%;
}

.header_dropdown_item-label {
    width: 100%;
    white-space: pre-wrap;
    line-height: 19px;
}

@media screen and (max-width: 859px) {
    .header_dropdown_item > .ant-space-item {
        max-width: 86% !important;
     }
     .nav__list > .ant-menu-submenu > .ant-menu >.ant-menu-item {
        height: auto !important;
     }
  }

  .drawer {
    margin-top: 48px;
  }

  .ant-drawer-mask {
    background: transparent !important;
  }

  .ant-drawer-body {
    padding-top: 0 !important;
  }