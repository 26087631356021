.video {
    height: 680px;
    border-radius: 10px;
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid royalblue;
    padding: 5px;
}

@media screen and (max-width: 500px) {
    .video {
        height: 200px;
        width: 100%;
        object-fit: contain;
    }
}