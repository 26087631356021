/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

/* variables css */
:root {
  --header-height: 3rem;

  /* colors */
  --first-color: #5f9bf1;
  --first-color-alt: #c42130;
  --first-color-send: #de3b49;
  --title-color: #161212;
  --text-color: #5b5757;
  --text-color-light: #8f8a8a;
  --text-color-white: #fff;
  --body-color: #fefbfb;
  --container-color: #fff;

  /* font and typography */
  --body-font: "Poppins", sans-serif;
  --biggest-font-size-sp: 3rem;
  --biggest-font-size: 2rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;

  /* font weight */
  --font-semi-bold: 600;
  --font-bold: 700;

  /* Margin */
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /* z-index */
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 3rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}

/* base  */
*,
::after,
::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/*========== Variables DARK theme ==========*/
body.dark-theme {
  --first-color-send: #161212;
  --title-color: #f3f1f1;
  --text-color: #d1c7c8;
  --body-color: #251d1e;
  --container-color: #302728;
}

/*========== BUTTON DARK/LIGHT ==========*/
.change-theme {
  position: absolute;
  right: 1.5rem;
  top: 2.2rem;
  display: flex;
  cursor: pointer;
  font-size: 2rem;
}

html,
body {
  scroll-behavior: smooth
}

body {
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
}

h1,
h2,
h3,
ul,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
}

.section-title,
.section-title-center {
  font-size: var(--biggest-font-size-sp);
  color: var(--title-color);
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;

  margin-bottom: var(--mb-3);
}

.section-title {
  font-size: var(--biggest-font-size-sp);
  font-weight: 400;
}

/*========== LAYOUT ==========*/
.l-main {
  overflow: hidden;
  padding-top: var(--header-height);
}

.bd-container {
  max-width: 1200px;
  width: calc(100% - 3rem);
  margin: auto;
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/* ========== NAV ========== */
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.nav__list {
  display: flex;
}

.nav__item {
  /* margin-bottom: var(--mb-3); */
}

.nav__link,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

.nav__link {
  padding: 10px;
}

.nav__logo {
  width: 150px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__logo img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__link {
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__toggle {
  font-size: 1.3rem;
  cursor: pointer;
}



/* show menu */
.show-menu {
  top: calc(var(--header-height) + 1rem);
}

.nav__menu__mobile {
  background-color: hsla(0deg, 0%, 80%, 0.98);
  left: 0;
  right: 0;
  position: absolute;
  top: -2000%;
  display: block;
  z-index: 1;
  transition: all .25s ease;
}

.nav__menu__mobile.active {
  /* display: block; */
  top: 100%;
}

.nav__menu__mobile .nav__list {
  display: flex;
  flex-direction: column;
}

.nav__menu__mobile .nav__list .nav__item {
  display: block;
  text-align: center;
  margin-bottom: 0;
  padding: 10px 0;

}

.nav__menu__mobile .nav__list .nav__item .nav__link {
  font-weight: 300;
}

@media screen and (min-width: 860px) {
  .nav__toggle {
    display: none;
  }

  .nav__menu__mobile {
    display: none;
  }
}

@media screen and (max-width: 859px) {
  .nav__menu {
    display: none;
  }
}

/* change bgc header */
.scroll-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* scrolltop */
.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  background: rgba(218, 37, 53, 0.5);
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  visibility: hidden;
  transition: 0.4s;
}

.scrolltop:hover {
  background-color: var(--first-color);
}

.scrolltop__icon {
  font-size: 1.5rem;
  color: var(--body-color);
}

.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}

/* ========== HOME ========== */
.home__container {
  row-gap: 0.5rem;
  height: 100vh;
  background-image: url("../src/img/home2.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50%;
  position: relative;
}

.home__data {
  position: absolute;
  top: 25%;
  left: 0;
  width: 600px;
}

.home__title {
  font-size: var(--biggest-font-size-sp);
  font-weight: 400;
  /* text-transform: uppercase; */
  margin-bottom: var(--mb-2);
}

.home__description {
  margin-bottom: var(--mb-3);
}

.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: var(--font-semi-bold);
  transition: 0.3s;

  border: 1px solid var(--first-color);
}

.button-link {
  background: none;
  padding: 0;
  color: var(--first-color);
}

.button-link:hover {
  background-color: transparent;
  color: var(--first-color-alt);
}

.button:hover {
  border: 1px solid #007bff;
  background-color: transparent;
  color: #007bff;
}

@media screen and (max-width: 967px) {
  .home__title {
    font-size: var(--biggest-font-size);
  }
  .section-title {
    font-size: var(--biggest-font-size);
  }

  .button {
    padding: 0.5rem 0.75rem;
    font-weight: 300;
    font-size: 1rem;
  }

  .section {
    padding: 2rem 0;
  }
}

@media screen and (max-width: 767px) {
  .home__title {
    font-size: var(--h2-font-size);
  }
  .section-title {
    font-size: var(--h2-font-size);
  }
}

@media screen and (max-width: 500px) {
  .home__data {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    text-align: center;
    /* background-color: rgba(0, 0, 0, 0.1); */
    padding: 10px;
    z-index: 2;
    text-transform: uppercase;
  }

  .home__container {
    background-size: 100%;
    background-position: bottom;
    /* opacity: 0.8; */
    z-index: 3;
    height: 70vh;
  }

  .section {
    padding: 3rem 0;
  }

  .button {
    display: none;
  }

  .home__title {
    color: var(--first-color);

  }
  .section-title {
    color: var(--first-color);

  }

  .home__description {
    text-transform: lowercase;
  }
}

/* ========== SHARE ========== */
.share__data {
  text-align: center;
}

.share__description {
  margin-bottom: var(--mb-2);
}

.share__img {
  width: 280px;
  justify-self: center;
}

/* ========== SHARE ========== */
.decoration {
  /* height: 100vh; */
}

.decoration__container {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.decoration__data {
  padding: 1rem 1rem 2rem;
  background-color: var(--container-color);
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.15);
  border-radius: 1rem;
}

.decoration__description {
  margin-bottom: 2rem;
}

.decoration__data:hover {
  box-shadow: 0 3px 12px rgba(65, 11, 16, 0.15);
}

.decoration__img {
  display: flex;
  align-items: center;
  /* width: 180px; */
  height: 350px;
}

.decoration__title {
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-1);
  text-transform: uppercase;
}

@media screen and (max-width: 967px) {
  .decoration__description {
    margin-bottom: 1rem;
  }

  .bd-grid {
    gap: .5rem;
  }
}

/* ========== ACCESSORIES ========== */
.accessory {
  height: 100vh;

}

.accessory__container {
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 2rem;
}

.accessory__content {
  display: grid;
  position: relative;
  padding: 0.25rem 0.75rem 0.75rem;
  background-color: var(--container-color);
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.15);
  border-radius: 1rem;
}

.accessory__content:hover {
  box-shadow: 0 3px 12px rgba(65, 11, 16, 0.15);
}

.accessory__img {
  width: 110px;
  justify-self: center;
  margin-bottom: 0.25rem;
}

.accessory__title,
.accessory__category {
  text-align: center;
}

.accessory__title {
  font-size: var(--normal-font-size);
}

.accessory__category {
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
  color: var(--text-color-light);
}

.accessory__price {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.accessory__button {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  font-size: 1.25rem;
  padding: 0.5rem 0.625rem;
  border-radius: 1rem 0 1rem 0;
}

.products__description {
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
}

/* ========== SEND GIFT ========== */
.send {
  background-color: var(--first-color-send);
}

.send__title,
.send__description {
  color: #fff;
}

.send__description {
  text-align: center;
  margin-bottom: var(--mb-4);
}

.send__direction {
  display: flex;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.send__input {
  width: 90%;
  outline: none;
  border: none;
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
}

.send__input::placeholder {
  font-family: var(--body-font);
}

.send__img {
  width: 280px;
  justify-self: center;
}

.contact__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__data {
  text-align: center;
}

.contact__form {
  width: 60%;
}

.formContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formItemWrap {
  display: flex;
  gap: 10px;
}

.formItem {
  width: 100%;
}

.formItem input {
  width: 100%;
}

form input,
form textarea {
  padding: 10px;
}

form button {
  padding: 16px 24px;
  align-self: flex-end;
}

.formItemWrap input {
  flex: 1;
}

.map {
  display: flex;
  justify-self: center;
  border: none;
}

.map__config {
  max-width: 1200px;
  width: 1024px;
  margin: 0 auto;
  min-height: 600px;
  border: none;
}

/* ========== FOOTER ========== */
.footer {
  background-color: #004a99;
}

.footer__container {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  opacity: 0.8;
}

.footer__logo {
  color: var(--text-color-white);
}

.footer__title {
  margin-bottom: var(--mb-2);
  color: var(--text-color-white);

}

.footer__description {
  color: var(--text-color-white);

}

.footer__logo,
.footer__title {
  font-size: var(--h3-font-size);
  text-transform: uppercase;
}

.footer__link {
  display: inline-block;
  margin-bottom: 0.75rem;
  color: var(--text-color-white);
}

.footer__link:hover {
  color: var(--first-color);
}

.footer__social {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-right: var(--mb-3);
}

.footer__social:hover {
  color: var(--first-color);
}

.footer__form {
  display: flex;
  margin-top: 20px;
}

.footer__form input {
  padding: 8px;
  border: none;
}

.footer__form button {
  background-color: #007bff;
  border: none;
  color: #fff;
}

.footer__copy {
  text-align: center;
  font-size: var(--small-font-size);
  color: var(--text-color-white);
  margin-top: 4rem;
}

.footer__description-link {
  text-decoration: underline;
  color: #fff;
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 967px) {}

@media screen and (min-width: 968px) {}